import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Cindy Beals" subtitle="Director of Learning" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQb/xAAYAQACAwAAAAAAAAAAAAAAAAACAwABBP/aAAwDAQACEAMQAAABjPp9AU5o0Yt+tLzO1YfLH//EABwQAAICAgMAAAAAAAAAAAAAAAEDAAIEERITIv/aAAgBAQABBQIDZti2rSLOrsOlTGT2FnhPERDgosbZhSgMp//EABkRAQACAwAAAAAAAAAAAAAAAAEAAhAREv/aAAgBAwEBPwGgJgdE5Z//xAAZEQEAAgMAAAAAAAAAAAAAAAABAAIQESH/2gAIAQIBAT8Buo4ezc//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhAhASMZH/2gAIAQEABj8CgnSY3Wr6RlGnTcl+HJ5M/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBEP/aAAgBAQABPyFUHZePgD2AwZZgs8A0XtEMC0nCyNcTAcYDrnEQAvAJ/9oADAMBAAIAAwAAABDnBr7/xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxBQtsOZ7Ddv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBASIU1Zen/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFxUZGx/9oACAEBAAE/EF2hVL9B2/YlNM0im+oclrQVUjuWmZA4XglAdKkecTdHqFuoilIu4kScBwlrKoouCf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg",
              "srcSet": ["/static/d96684a13003e39bf26e4119d235d896/f93b5/01.jpg 300w", "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`During the 2019 - 2020 school year PSI welcomed its first ever Director of Learning - a new and significant role for the School.`}</p>
    </blockquote>
    <p>{`Whilst involved in many aspects of learning at the school, for this first year  I was charged with two main areas: bringing more data analysis and evidence-informed decisions to the school, and creating a culture of coaching.`}</p>
    <p>{`Data analysis has come through involvement in many aspects of the school, particularly external assessments and the measures associated with the Strategic Plan Goals and Leading School Benchmarks.  For more on these areas, please see articles on these topics elsewhere in this annual report.`}</p>
    <p>{`A culture of coaching has involved working with faculty, cultivating their own desire to learn and improve, through setting, working on, and then reflecting upon goals.  In order to define PSI’s approach to coaching, a group of faculty convened and developed the following definition: “Coaches and buddies at PSI support and encourage colleagues who willingly engage in a process of enhancing their teaching practices. Through a process of goal setting, dialogue and reflection, they assist others in focusing on the here and now as well as keeping an eye on the future.”  Whilst PSI already has a Literacy Coach in the Primary School, and a Tech Integration Coach in the Secondary School, in order to further the goal of personalising professional growth and student learning experiences, next year, we have hired a whole school Instructional Coach.`}</p>
    <p>{`Another way to instill a culture of coaching came through the roll out of the Teacher Growth Plan (TGP). This plan was developed during the 2018 - 2019 school-year by a small committee of teachers and the school director, based on research and the philosophy that teachers’ growth is like that of students: they need to be able to pursue their own passions in a personalised way and work in teams to develop skills, acquire knowledge and apply understanding.`}</p>
    <p>{`In this new approach to faculty development, teachers set aspirational, learner-centred goals, where at least one of their goals aligns with the school’s visionary Strategic Plan Goals, and is connected to an area of their own interest for growth. The teachers work with a reflective partner called a goal buddy, to help them consider how to approach their professional development in order to improve the students’ learning experiences.`}</p>
    <p>{`As part of this improved approach to teacher development, returning teachers had access to individual professional development funds to allow them to attend workshops or receive training in an area aligned with their goal.  While 58% of teachers were approved for TGP funded training, some of these were unfortunately cancelled due to the pandemic in the spring.`}</p>
    <p>{`In the recent climate survey, we want to celebrate two areas associated with a culture of coaching.  Faculty were asked their agreement with the statement, “I have ownership of my own professional development,” there was a very significant increase from 59% agreeing or strongly agreeing in 2019, up to 91% this year.  Additionally, in response to the question “Involvement in coaching or intervention has enhanced my practice.”  We note that there was a significant increase in agreement to this question, with 70% agreeing or strongly agreeing this year as compared to only 45% last year.  With the addition of the new whole school Instructional Coach, we hope to see an even larger increase in agreement for next year.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      